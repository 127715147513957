<template lang="">
  <Card>
    <template #header> Tapu Bilgileri </template>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="">Emlak Kodu</label>
          <input type="text" class="form-control" />
        </div>
      </div>
      <div class="col-md-6 col-12">
        <div class="form-group">
          <label for="yetkiBelgeNo">İl </label>
          <select
            class="form-control"
            id="exampleFormControlSelect1"
            @change="countyChange($event)"
          >
            <option
              v-for="iller in counties"
              :key="iller.plaka"
              :value="iller.plaka"
            >
              {{ iller.il }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-md-6 col-12">
        <div class="form-group">
          <label for="yetkiBelgeNo">İlçe </label>
          <select class="form-control">
            <option value="" selected>Seçiniz</option>
            <option v-for="(ilceler, key) in districts" :key="key" :value="key">
              {{ ilceler }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="">Mahalle</label>
          <select name="" id="mahalle" class="form-control">
            <option value="" selected>Seçiniz</option>
            <option value="">A MAhallesi</option>
            <option value="">B MAhallesi</option>
            <option value="">C MAhallesi</option>
          </select>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="">Cadde</label>
          <input type="text" class="form-control" />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="">Sokak</label>
          <input type="text" class="form-control" />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="">Dış Kapı Numarası</label>
          <input type="text" class="form-control" />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="">İç Kapı Numarası</label>
          <input type="text" class="form-control" />
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="">Mahal Alanı</label>
          <input type="text" class="form-control" />
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="">Yasal Alan</label>
          <input type="text" class="form-control" />
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="">Yüzölçümü</label>
          <input type="text" class="form-control" />
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="">Pafta</label>
          <input type="text" class="form-control" />
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="">Ada</label>
          <input type="text" class="form-control" />
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="">Parsel </label>
          <input type="text" class="form-control" />
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="">Ada (Eski)</label>
          <input type="text" class="form-control" />
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="">Parsel (Eski)</label>
          <input type="text" class="form-control" />
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <label for="">Tapu Türü</label>
          <select name="" id="tapuTur" class="form-control">
            <option value="" selected>Seçiniz</option>
            <option value="">Tapu Yok</option>
            <option value="">Kat irtifakı</option>
            <option value="">Cins Tashili Yapı</option>
          </select>
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <label for="">İmar Lejant Fonksiyonu</label>
          <select name="" id="imarLejant" class="form-control">
            <option value="" selected>Seçiniz</option>
            <option value="" selected>Seçiniz</option>
            <option value="">Konut</option>
            <option value="">Ticari</option>
            <option value="">Konut ve Ticari</option>
            <option value="">Turizm</option>
          </select>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="">Fiili Kullanım Amacı *</label>
          <select name="" id="fiiliKullanim" class="form-control">
            <option value="" selected>Seçiniz</option>
            <option value="">Daire</option>
            <option value="">Dublex Daire</option>
            <option value="">Tripleks Daire</option>
            <option value="">Residence</option>
          </select>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="">Fiili Kullanım Niteliği *</label>
          <select name="" id="fiiliKullanim" class="form-control">
            <option value="" selected>Seçiniz</option>
            <option value="">Konut</option>
            <option value="">Akaryakıt</option>
            <option value="">Büro</option>
            <option value="">Dükkan</option>
          </select>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="">Bağımsız Bölüm Blok</label>
          <input type="text" class="form-control" />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="">Bağımsız Bölüm Giriş</label>
          <input type="text" class="form-control" />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="">Bağımsız Bölüm No</label>
          <input type="text" class="form-control" />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="">Bağımsız Bölümün Tapudaki Niteliği</label>
          <input type="text" class="form-control" />
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="">Taşınmaz ID *</label>
          <input type="text" class="form-control" />
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="">Uavt Kodu</label>
          <input type="text" class="form-control" />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="">İmar Planı İçinde *</label>
          <select name="" id="imarPlan" class="form-control">
            <option value="" selected>Seçiniz</option>
            <option value="">Evet</option>
            <option value="">Hayır</option>
          </select>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="">Yevmiye No *</label>
          <input type="text" class="form-control" />
        </div>
      </div>
    </div>

    <!-- Malikler -->
    <h4 class="mt-5 mx-3 mb-5">Malikler</h4>
    <div class="row">
      <!-- trash icon -->
      <div
        class="col-1"
        style="
          max-width: 8.33333333%;
          justify-content: center;
          align-items: center;
        "
      >
        <b-button variant="light" @click="deleteRow">
          <i class="flaticon2-trash p-0"></i>
        </b-button>
      </div>
      <!-- hissedar -->
      <div class="col">
        <div class="form-group">
          <select
            class="form-control"
            :name="'filli-' + v"
            id="exampleFormControlSelect1"
          >
            <option selected>Hissedar</option>
            <option>ŞEKERBANK T.A.Ş.</option>
            <option>Odeabank</option>
            <option>VakıfBank</option>
            <option>Alternatif Bank</option>
            <option>Alternatif Leasing</option>
            <option>Anadolubank</option>
            <option>Burgan Bank</option>
            <option>Fibabanka</option>
            <option>İstanbul Varlık</option>
            <option>Perabank</option>
            <option>TÜRKİYE FİNANS KATILIM BANKASI A.Ş.</option>
            <option>ING BANK A.Ş.</option>
            <option>ERe-Pie Portföy Yönetimi A.Ş.</option>
            <option>Sahibinden</option>
            <option>Murat Eser</option>
            <option>ZİYA EREN</option>
            <option>TÜRKİYE İŞ BANKASI</option>
            <option>TÜRKİYE EMLAK KATILIM BANKASI A.Ş.</option>
            <option>YAPI VE KREDİ BANKASI A.Ş</option>
          </select>
        </div>
      </div>
      <!-- hisse pay -->
      <div class="col">
        <b-form-group class="add-style">
          <b-form-input type="text" id="ad" required />
        </b-form-group>
      </div>
      <!-- hisse pay -->
      <div class="col">
        <b-form-group class="add-style">
          <b-form-input type="text" id="ad" required />
        </b-form-group>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <b-button variant="light" @click="malikEkle">
          Malik Ekle
          <i class="flaticon2-plus p-0 ml-2"></i>
        </b-button>
      </div>
    </div>

    <h4 class="mt-5 mx-3 mb-5">Özellikler</h4>
    <div class="row">
      <div class="col-md-3">
        <div class="form-group">
          <label for="">Bina Kat Sayısı *</label>
          <input type="text" class="form-control" value="1" />
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="">Bulunduğu Kat *</label>
          <select name="" id="bulunduguKat" class="form-control">
            <option value="" selected>Seçiniz</option>
            <option value="">Zemin</option>
            <option value="">1. Kat</option>
            <option value="">2. Kat</option>
          </select>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="">Yapı Cins *</label>
          <select name="" id="yapiCins" class="form-control">
            <option value="" selected>Seçiniz</option>
            <option value="">Karkas</option>
            <option value="">Ahşap</option>
            <option value="">Çelik</option>
          </select>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="">Bina İnşa Yılı *</label>
          <input type="text" class="form-control" value="2023" />
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="">Oda Sayısı *</label>
          <input type="text" class="form-control" value="1" />
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="">Salon Sayısı</label>
          <input type="text" class="form-control" value="0" />
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="">Banyo Sayısı</label>
          <input type="text" class="form-control" value="0" />
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="">Wc Sayısı</label>
          <input type="text" class="form-control" value="0" />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="">Balkon Sayısı</label>
          <input type="text" class="form-control" value="0" />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="">Kullanım Durumu</label>
          <select name="" id="kullanimDurum" class="form-control">
            <option value="" selected>Seçiniz</option>
            <option value="">Diğer</option>
            <option value="">Boş</option>
            <option value="">Kiracı</option>
          </select>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="">Krediye Uygun Mu *</label>
          <select name="" id="krediUygun" class="form-control">
            <option value="" selected>Seçiniz</option>
            <option value="">Hayır</option>
            <option value="">Evet</option>
            <option value="">Diğer</option>
          </select>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="">Asansor *</label>
          <select name="" id="asansor" class="form-control">
            <option value="" selected>Seçiniz</option>
            <option value="">Yok</option>
            <option value="">Var</option>
          </select>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="">Isıtma Sistemi *</label>
          <select name="" id="isitma" class="form-control">
            <option value="" selected>Seçiniz</option>
            <option value="">Soba</option>
            <option value="">Doğalgaz</option>
            <option value="">Diğer</option>
          </select>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="">Yapı Durumu *</label>
          <select name="" id="yapiDurumu" class="form-control">
            <option value="" selected>Seçiniz</option>
            <option value="">İkinci El</option>
            <option value="">Yeni</option>
          </select>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="">Aidat Tutarı</label>
          <input type="text" class="form-control" />
        </div>
      </div>
    </div>

    <h4 class="mt-5 mx-3 mb-5">Diğer Özellikler</h4>
    <div class="row">
      <div class="col-12 mt-5">
        <div>
          <b-form-checkbox-group
            id="checkbox-group-1"
            v-model="selected"
            :options="options"
            name="flavour-1"
          ></b-form-checkbox-group>
          <b-form-group label="Cephe">
            <b-form-checkbox-group
              id="checkbox-group-2"
              v-model="selected"
              name="flavour-2"
            >
              <b-form-checkbox value="bati" size="lg">Batı</b-form-checkbox>
              <b-form-checkbox value="dogu" size="lg">Doğu</b-form-checkbox>
              <b-form-checkbox value="guney" size="lg">Güney</b-form-checkbox>
              <b-form-checkbox value="kuzey" size="lg">Kuzey</b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>
        </div>
      </div>
      <div class="col-12 mt-5">
        <div>
          <b-form-checkbox-group
            id="checkbox-group-1"
            v-model="selected"
            :options="options"
            name="flavour-1"
          ></b-form-checkbox-group>
          <b-form-group label="Dış Özellikleri">
            <b-form-checkbox-group
              id="checkbox-group-2"
              v-model="selected"
              name="flavour-2"
            >
              <b-form-checkbox value="bati" size="lg">Batı</b-form-checkbox>
              <b-form-checkbox value="dogu" size="lg">Doğu</b-form-checkbox>
              <b-form-checkbox value="guney" size="lg">Güney</b-form-checkbox>
              <b-form-checkbox value="kuzey" size="lg">Kuzey</b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>
        </div>
      </div>
      <div class="col-12 mt-5">
        <div>
          <b-form-checkbox-group
            id="checkbox-group-1"
            v-model="selected"
            :options="options"
            name="flavour-1"
          ></b-form-checkbox-group>
          <b-form-group label="Engelliye Uygun">
            <b-form-checkbox-group
              id="checkbox-group-2"
              v-model="selected"
              name="flavour-2"
            >
              <b-form-checkbox value="bati" size="lg">Batı</b-form-checkbox>
              <b-form-checkbox value="dogu" size="lg">Doğu</b-form-checkbox>
              <b-form-checkbox value="guney" size="lg">Güney</b-form-checkbox>
              <b-form-checkbox value="kuzey" size="lg">Kuzey</b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>
        </div>
      </div>
      <div class="col-12 mt-5">
        <div>
          <b-form-checkbox-group
            id="checkbox-group-1"
            v-model="selected"
            :options="options"
            name="flavour-1"
          ></b-form-checkbox-group>
          <b-form-group label="Muhit">
            <b-form-checkbox-group
              id="checkbox-group-2"
              v-model="selected"
              name="flavour-2"
            >
              <b-form-checkbox value="bati" size="lg">Batı</b-form-checkbox>
              <b-form-checkbox value="dogu" size="lg">Doğu</b-form-checkbox>
              <b-form-checkbox value="guney" size="lg">Güney</b-form-checkbox>
              <b-form-checkbox value="kuzey" size="lg">Kuzey</b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>
        </div>
      </div>
      <div class="col-12 mt-5">
        <div>
          <b-form-checkbox-group
            id="checkbox-group-1"
            v-model="selected"
            :options="options"
            name="flavour-1"
          ></b-form-checkbox-group>
          <b-form-group label="Ulaşım">
            <b-form-checkbox-group
              id="checkbox-group-2"
              v-model="selected"
              name="flavour-2"
            >
              <b-form-checkbox value="bati" size="lg">Batı</b-form-checkbox>
              <b-form-checkbox value="dogu" size="lg">Doğu</b-form-checkbox>
              <b-form-checkbox value="guney" size="lg">Güney</b-form-checkbox>
              <b-form-checkbox value="kuzey" size="lg">Kuzey</b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>
        </div>
      </div>
      <div class="col-12 mt-5">
        <div>
          <b-form-checkbox-group
            id="checkbox-group-1"
            v-model="selected"
            :options="options"
            name="flavour-1"
          ></b-form-checkbox-group>
          <b-form-group label="Manzara">
            <b-form-checkbox-group
              id="checkbox-group-2"
              v-model="selected"
              name="flavour-2"
            >
              <b-form-checkbox value="bati" size="lg">Batı</b-form-checkbox>
              <b-form-checkbox value="dogu" size="lg">Doğu</b-form-checkbox>
              <b-form-checkbox value="guney" size="lg">Güney</b-form-checkbox>
              <b-form-checkbox value="kuzey" size="lg">Kuzey</b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>
        </div>
      </div>
      <div class="col-12 mt-5">
        <div>
          <b-form-checkbox-group
            id="checkbox-group-1"
            v-model="selected"
            :options="options"
            name="flavour-1"
          ></b-form-checkbox-group>
          <b-form-group label="Konut Tipi">
            <b-form-checkbox-group
              id="checkbox-group-2"
              v-model="selected"
              name="flavour-2"
            >
              <b-form-checkbox value="bati" size="lg">Batı</b-form-checkbox>
              <b-form-checkbox value="dogu" size="lg">Doğu</b-form-checkbox>
              <b-form-checkbox value="guney" size="lg">Güney</b-form-checkbox>
              <b-form-checkbox value="kuzey" size="lg">Kuzey</b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>
        </div>
      </div>
      <div class="col-12 mt-5">
        <div>
          <b-form-checkbox-group
            id="checkbox-group-1"
            v-model="selected"
            :options="options"
            name="flavour-1"
          ></b-form-checkbox-group>
          <b-form-group label="Alt Yapı">
            <b-form-checkbox-group
              id="checkbox-group-2"
              v-model="selected"
              name="flavour-2"
            >
              <b-form-checkbox value="bati" size="lg">Batı</b-form-checkbox>
              <b-form-checkbox value="dogu" size="lg">Doğu</b-form-checkbox>
              <b-form-checkbox value="guney" size="lg">Güney</b-form-checkbox>
              <b-form-checkbox value="kuzey" size="lg">Kuzey</b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>
        </div>
      </div>
      <div class="col-12 mt-5">
        <div>
          <b-form-checkbox-group
            id="checkbox-group-1"
            v-model="selected"
            :options="options"
            name="flavour-1"
          ></b-form-checkbox-group>
          <b-form-group label="Genel Özellikler">
            <b-form-checkbox-group
              id="checkbox-group-2"
              v-model="selected"
              name="flavour-2"
            >
              <b-form-checkbox value="bati" size="lg">Batı</b-form-checkbox>
              <b-form-checkbox value="dogu" size="lg">Doğu</b-form-checkbox>
              <b-form-checkbox value="guney" size="lg">Güney</b-form-checkbox>
              <b-form-checkbox value="kuzey" size="lg">Kuzey</b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>
        </div>
      </div>
    </div>

    <h4 class="mt-5 mx-3 mb-5">Harita Koordinatları</h4>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="">Enlem</label>
          <input type="text" class="form-control" />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="">Enlem</label>
          <input type="text" class="form-control" />
        </div>
      </div>
    </div>
    <div class="buttons">
      <b-form-group>
        <b-button variant="primary" @click="addFileModal = true"
          >Çoğalt</b-button
        >
        <b-button variant="success" class="ml-4">Kaydet</b-button>
      </b-form-group>
    </div>
    <b-modal v-model="addFileModal" id="note-modal">
      <template #modal-header>
        <h4> Adet</h4>
      </template>
      <div class="form-group">
        <input type="text" class="form-control" id="aciklama">
      </div>
      <template #modal-footer>
        <div class="d-flex justify-content-end w-100">
          <b-button variant="light" @click="addFileModal = false">
            Vazgeç
          </b-button>
          <b-button variant="success" @click="addNewNote" class="ml-4">
            Çoğalt
          </b-button>
        </div>
      </template>
    </b-modal>
    
  </Card>
  
</template>
<script>
export default {
  data() {
    return {
      addFileModal:false,
        search: "",
        filters: "",
        showInsertUpdate: false,
        showDetail: false,
        showImportModal: false,
        resetForm: false,
        confirmCreate: false,
        loading: false,
      districts: [],
      counties: [
        {
          il: "Adana",
          plaka: 1,
          ilceleri: [
            "Aladağ",
            "Ceyhan",
            "Çukurova",
            "Feke",
            "İmamoğlu",
            "Karaisalı",
            "Karataş",
            "Kozan",
            "Pozantı",
            "Saimbeyli",
            "Sarıçam",
            "Seyhan",
            "Tufanbeyli",
            "Yumurtalık",
            "Yüreğir",
          ],
        },
        {
          il: "Adıyaman",
          plaka: 2,
          ilceleri: [
            "Besni",
            "Çelikhan",
            "Gerger",
            "Gölbaşı",
            "Kahta",
            "Merkez",
            "Samsat",
            "Sincik",
            "Tut",
          ],
        },
        {
          il: "Afyonkarahisar",
          plaka: 3,
          ilceleri: [
            "Başmakçı",
            "Bayat",
            "Bolvadin",
            "Çay",
            "Çobanlar",
            "Dazkırı",
            "Dinar",
            "Emirdağ",
            "Evciler",
            "Hocalar",
            "İhsaniye",
            "İscehisar",
            "Kızılören",
            "Merkez",
            "Sandıklı",
            "Sinanpaşa",
            "Sultandağı",
            "Şuhut",
          ],
        },
        {
          il: "Ağrı",
          plaka: 4,
          ilceleri: [
            "Diyadin",
            "Doğubayazıt",
            "Eleşkirt",
            "Hamur",
            "Merkez",
            "Patnos",
            "Taşlıçay",
            "Tutak",
          ],
        },
        {
          il: "Amasya",
          plaka: 5,
          ilceleri: [
            "Göynücek",
            "Gümüşhacıköy",
            "Hamamözü",
            "Merkez",
            "Merzifon",
            "Suluova",
            "Taşova",
          ],
        },
        {
          il: "Ankara",
          plaka: 6,
          ilceleri: [
            "Altındağ",
            "Ayaş",
            "Bala",
            "Beypazarı",
            "Çamlıdere",
            "Çankaya",
            "Çubuk",
            "Elmadağ",
            "Güdül",
            "Haymana",
            "Kalecik",
            "Kızılcahamam",
            "Nallıhan",
            "Polatlı",
            "Şereflikoçhisar",
            "Yenimahalle",
            "Gölbaşı",
            "Keçiören",
            "Mamak",
            "Sincan",
            "Kazan",
            "Akyurt",
            "Etimesgut",
            "Evren",
            "Pursaklar",
          ],
        },
        {
          il: "Antalya",
          plaka: 7,
          ilceleri: [
            "Akseki",
            "Alanya",
            "Elmalı",
            "Finike",
            "Gazipaşa",
            "Gündoğmuş",
            "Kaş",
            "Korkuteli",
            "Kumluca",
            "Manavgat",
            "Serik",
            "Demre",
            "İbradı",
            "Kemer",
            "Aksu",
            "Döşemealtı",
            "Kepez",
            "Konyaaltı",
            "Muratpaşa",
          ],
        },
        {
          il: "Artvin",
          plaka: 8,
          ilceleri: [
            "Ardanuç",
            "Arhavi",
            "Merkez",
            "Borçka",
            "Hopa",
            "Şavşat",
            "Yusufeli",
            "Murgul",
          ],
        },
        {
          il: "Aydın",
          plaka: 9,
          ilceleri: [
            "Merkez",
            "Bozdoğan",
            "Efeler",
            "Çine",
            "Germencik",
            "Karacasu",
            "Koçarlı",
            "Kuşadası",
            "Kuyucak",
            "Nazilli",
            "Söke",
            "Sultanhisar",
            "Yenipazar",
            "Buharkent",
            "İncirliova",
            "Karpuzlu",
            "Köşk",
            "Didim",
          ],
        },
        {
          il: "Balıkesir",
          plaka: 10,
          ilceleri: [
            "Altıeylül",
            "Ayvalık",
            "Merkez",
            "Balya",
            "Bandırma",
            "Bigadiç",
            "Burhaniye",
            "Dursunbey",
            "Edremit",
            "Erdek",
            "Gönen",
            "Havran",
            "İvrindi",
            "Karesi",
            "Kepsut",
            "Manyas",
            "Savaştepe",
            "Sındırgı",
            "Gömeç",
            "Susurluk",
            "Marmara",
          ],
        },
        {
          il: "Bilecik",
          plaka: 11,
          ilceleri: [
            "Merkez",
            "Bozüyük",
            "Gölpazarı",
            "Osmaneli",
            "Pazaryeri",
            "Söğüt",
            "Yenipazar",
            "İnhisar",
          ],
        },
        {
          il: "Bingöl",
          plaka: 12,
          ilceleri: [
            "Merkez",
            "Genç",
            "Karlıova",
            "Kiğı",
            "Solhan",
            "Adaklı",
            "Yayladere",
            "Yedisu",
          ],
        },
        {
          il: "Bitlis",
          plaka: 13,
          ilceleri: [
            "Adilcevaz",
            "Ahlat",
            "Merkez",
            "Hizan",
            "Mutki",
            "Tatvan",
            "Güroymak",
          ],
        },
        {
          il: "Bolu",
          plaka: 14,
          ilceleri: [
            "Merkez",
            "Gerede",
            "Göynük",
            "Kıbrıscık",
            "Mengen",
            "Mudurnu",
            "Seben",
            "Dörtdivan",
            "Yeniçağa",
          ],
        },
        {
          il: "Burdur",
          plaka: 15,
          ilceleri: [
            "Ağlasun",
            "Bucak",
            "Merkez",
            "Gölhisar",
            "Tefenni",
            "Yeşilova",
            "Karamanlı",
            "Kemer",
            "Altınyayla",
            "Çavdır",
            "Çeltikçi",
          ],
        },
        {
          il: "Bursa",
          plaka: 16,
          ilceleri: [
            "Gemlik",
            "İnegöl",
            "İznik",
            "Karacabey",
            "Keles",
            "Mudanya",
            "Mustafakemalpaşa",
            "Orhaneli",
            "Orhangazi",
            "Yenişehir",
            "Büyükorhan",
            "Harmancık",
            "Nilüfer",
            "Osmangazi",
            "Yıldırım",
            "Gürsu",
            "Kestel",
          ],
        },
        {
          il: "Çanakkale",
          plaka: 17,
          ilceleri: [
            "Ayvacık",
            "Bayramiç",
            "Biga",
            "Bozcaada",
            "Çan",
            "Merkez",
            "Eceabat",
            "Ezine",
            "Gelibolu",
            "Gökçeada",
            "Lapseki",
            "Yenice",
          ],
        },
        {
          il: "Çankırı",
          plaka: 18,
          ilceleri: [
            "Merkez",
            "Çerkeş",
            "Eldivan",
            "Ilgaz",
            "Kurşunlu",
            "Orta",
            "Şabanözü",
            "Yapraklı",
            "Atkaracalar",
            "Kızılırmak",
            "Bayramören",
            "Korgun",
          ],
        },
        {
          il: "Çorum",
          plaka: 19,
          ilceleri: [
            "Alaca",
            "Bayat",
            "Merkez",
            "İskilip",
            "Kargı",
            "Mecitözü",
            "Ortaköy",
            "Osmancık",
            "Sungurlu",
            "Boğazkale",
            "Uğurludağ",
            "Dodurga",
            "Laçin",
            "Oğuzlar",
          ],
        },
        {
          il: "Denizli",
          plaka: 20,
          ilceleri: [
            "Acıpayam",
            "Buldan",
            "Çal",
            "Çameli",
            "Çardak",
            "Çivril",
            "Merkez",
            "Merkezefendi",
            "Pamukkale",
            "Güney",
            "Kale",
            "Sarayköy",
            "Tavas",
            "Babadağ",
            "Bekilli",
            "Honaz",
            "Serinhisar",
            "Baklan",
            "Beyağaç",
            "Bozkurt",
          ],
        },
        {
          il: "Diyarbakır",
          plaka: 21,
          ilceleri: [
            "Kocaköy",
            "Çermik",
            "Çınar",
            "Çüngüş",
            "Dicle",
            "Ergani",
            "Hani",
            "Hazro",
            "Kulp",
            "Lice",
            "Silvan",
            "Eğil",
            "Bağlar",
            "Kayapınar",
            "Sur",
            "Yenişehir",
            "Bismil",
          ],
        },
        {
          il: "Edirne",
          plaka: 22,
          ilceleri: [
            "Merkez",
            "Enez",
            "Havsa",
            "İpsala",
            "Keşan",
            "Lalapaşa",
            "Meriç",
            "Uzunköprü",
            "Süloğlu",
          ],
        },
        {
          il: "Elazığ",
          plaka: 23,
          ilceleri: [
            "Ağın",
            "Baskil",
            "Merkez",
            "Karakoçan",
            "Keban",
            "Maden",
            "Palu",
            "Sivrice",
            "Arıcak",
            "Kovancılar",
            "Alacakaya",
          ],
        },
        {
          il: "Erzincan",
          plaka: 24,
          ilceleri: [
            "Çayırlı",
            "Merkez",
            "İliç",
            "Kemah",
            "Kemaliye",
            "Refahiye",
            "Tercan",
            "Üzümlü",
            "Otlukbeli",
          ],
        },
        {
          il: "Erzurum",
          plaka: 25,
          ilceleri: [
            "Aşkale",
            "Çat",
            "Hınıs",
            "Horasan",
            "İspir",
            "Karayazı",
            "Narman",
            "Oltu",
            "Olur",
            "Pasinler",
            "Şenkaya",
            "Tekman",
            "Tortum",
            "Karaçoban",
            "Uzundere",
            "Pazaryolu",
            "Köprüköy",
            "Palandöken",
            "Yakutiye",
            "Aziziye",
          ],
        },
      ],
    };
  },
  methods: {
    countyChange(e) {
      let filteredData = this.counties.filter(
        (county) => county.plaka == e.target.value
      );
      this.districts = filteredData[0].ilceleri;
    },
  },
};
</script>
<style lang="scss" scoped>
.buttons {
  display: flex;
justify-content: flex-end;
}
.saveButton {
  background-color: $dveb-success;
  padding: 8px 12px;
  border-radius: 10px;
  color: white;
}
.saveButton:hover {
  background-color: rgb(39, 117, 39);
  transition: 0.2s;
}
.addButton {
  background-color: $dveb-divider;
  padding: 8px 12px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
}
</style>
